<template>
  <div class="md-layout work">

    <div>
      <md-dialog :md-active.sync="showDialog" :md-fullscreen="false" :md-keep-alive="false">
        <md-dialog-title style="text-align: center !important;">博弈的一天开始了</md-dialog-title>

        <div class="dialog-address" v-if="work2.address">
          <h6>如果在<font class="shining-text" style="font-size: 15px;color: #42B3D5">{{work2.address}}</font>打卡的话,也许......</h6>
          <h6>今日抽到的牌是<font class="shining-text">{{ randomCard.label }}</font>,不知道有什么特别的意义......</h6>
        </div>


        <!-- 卡片容器 -->
        <div class="card-container">
          <div class="card" :class="{ flipped: isFlipped }" @transitionend="flippedEndMethod">
            <!-- 正面图片 -->
            <div class="card-front">
              <img :src="fontImg" :key="fontImg" alt="Front Image" class="fade-in-image">
            </div>
            <!-- 背面图片 -->
            <div class="card-back">
              <img :src="backImg" :key="backImg" alt="Back Image" class="fade-in-image">
            </div>
          </div>
        </div>
        <div style="padding: 20px !important;display:flex;justify-content: space-between !important;" >
          <md-button class="md-danger" @click="restartFilpped" :disabled="flippedFlag">重置地点</md-button>
          <md-button class="md-success" @click="confirmFilpped" :disabled="flippedFlag">加载该地址坐标</md-button>
        </div>
      </md-dialog>

<!--      <md-button class="md-primary md-raised" @click="showDialog = true">Show Dialog</md-button>-->
    </div>

    <div
        class="md-layout-item md-size-60 md-small-size-100 mx-auto"
    >
      <h4 class="title">
        地图选点
      </h4>

      <div class="md-layout collections">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <md-card>

            <md-card-header>
              <div id="handMap" style="height: 300px"></div>
            </md-card-header>
            <md-card-content>
              <el-select
                  ref="searchSelect"
                  v-model="searchLocationValue"
                  filterable
                  placeholder="输入地点进行搜索"
                  @change="searchLocationValueSelect"
                  @input.native="debounce(searchLocationValueChange(),500)"
                  :filter-method="()=>searchResults"
              >
                <el-option
                    v-for="(item,index) in searchResults"
                    :key="index"
                    :label="item.address"
                    :value="item.id">
                </el-option>
                <div  style="bottom: -10px">
                  <el-pagination
                      small
                      @current-change="handleCurrentChange"
                      :current-page="currentPage"
                      :page-size="pageSize"
                      layout="prev, pager,next,total"
                      :total="total"
                  >
                  </el-pagination>
                </div>
              </el-select>
            </md-card-content>

            <md-card-actions style="display: flex;justify-content: space-between !important;" >
              <md-button class="md-success" @click="confirmAddress">就要这个地点打卡！！！</md-button>
              <md-button class="md-success" @click="fixedPosition">获取当前位置</md-button>
            </md-card-actions>
          </md-card>

        </div>

      </div>
    </div>
    <div
        class="md-layout-item md-size-20 md-small-size-100 mx-auto stats"
    >
      <h4 class="title title-margin" v-if="!work.address">
        明细配置
      </h4>
      <h4 class="title title-margin" v-else>
        再确认下吧?
      </h4>
      <ul class="list-unstyled">
        <li><b>地址</b> {{ work.address }}</li>
        <li><b>经度</b> {{ work.lng }}</li>
        <li><b>纬度</b> {{ work.lat }}</li>
      </ul>
      <hr>
      <h4 class="title title-margin">
        或者推荐的地点配置?
      </h4>
      <p class="description">
<!--        <md-button class="md-success" style="width: 100%" @click="getSmzx">市民中心配置({{ smzx.length }}个配置随机)</md-button>-->
        <md-button class="md-success" style="width: 100%" @click="getCard">我要打牌</md-button>
      </p>
      <p class="description">
        <md-button class="md-success" style="width: 100%" @click="work = fwzx">政务服务中心配置</md-button>
      </p>

      <hr>
<!--      <h4 class="title title-margin">-->
<!--        打卡！！！！-->
<!--      </h4>-->
      <p class="description" >
        <md-button style="width: 100%" @click="workSign" class="md-danger">打卡</md-button>
      </p>
<!--      <badge type="primary">-->
<!--        Footwear-->
<!--      </badge>-->
<!--      <badge type="rose">-->
<!--        Luxury-->
<!--      </badge>-->
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import md5 from 'js-md5';
import {getLinewellUserInfo, setLinewellUser, workSign} from "@/api/linewell";
import fa from "element-ui/src/locale/lang/fa";
import moment from "moment";
import address from "address";



export default {
  components: {
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "manually-clock-page",
  computed:{

  },
  data() {
    return {
      fontImg: require('@/assets/img/taluopai/empty.jpg'),
      backImg: require('@/assets/img/taluopai/empty.jpg'),
      isFlipped: false,
      showDialog: false,
      cards:[{
        label: '愚者',
        name: 'fool',
        path: require('@/assets/img/taluopai/fool.jpg')
      },{
        label: '星币',
        name: 'ace_of_coins',
        path: require('@/assets/img/taluopai/ace_of_coins.jpg')
      },{
        label: '圣杯一.',
        name: 'ace_of_cups',
        path: require('@/assets/img/taluopai/ace_of_cups.jpg')
      },{
        label: '宝剑一',
        name: 'ace_of_swords',
        path: require('@/assets/img/taluopai/ace_of_swords.jpg')
      },{
        label: '权杖一',
        name: 'ace_of_wands',
        path: require('@/assets/img/taluopai/ace_of_wands.jpg')
      },{
        label: '战车',
        name: 'chariot',
        path: require('@/assets/img/taluopai/chariot.jpg')
      },{
        label: '死神',
        name: 'death',
        path: require('@/assets/img/taluopai/death.jpg')
      },{
        label: '恶魔',
        name: 'devil',
        path: require('@/assets/img/taluopai/devil.jpg')
      },{
        label: '皇帝',
        name: 'emperor',
        path: require('@/assets/img/taluopai/emperor.jpg')
      },{
        label: '女皇',
        name: 'empress',
        path: require('@/assets/img/taluopai/empress.jpg')
      },{
        label: '倒吊人',
        name: 'hanged_man',
        path: require('@/assets/img/taluopai/hanged_man.jpg')
      },{
        label: '隐士',
        name: 'hermit',
        path: require('@/assets/img/taluopai/hermit.jpg')
      },{
        label: '教皇',
        name: 'hierophant',
        path: require('@/assets/img/taluopai/hierophant.jpg')
      },{
        label: '女祭司',
        name: 'high_priestess',
        path: require('@/assets/img/taluopai/high_priestess.jpg')
      },{
        label: '审判',
        name: 'judgement',
        path: require('@/assets/img/taluopai/judgement.jpg')
      },{
        label: '正义',
        name: 'justice',
        path: require('@/assets/img/taluopai/justice.jpg')
      }
      // ,{
      //   label: '恋人',
      //   name: 'lovers',
      //   path: require('@/assets/img/taluopai/lovers.jpg')
      // }
      ,{
        label: '魔术师',
        name: 'magic',
        path: require('@/assets/img/taluopai/magic.jpg')
      },{
        label: '月亮',
        name: 'moon',
        path: require('@/assets/img/taluopai/moon.jpg')
      },{
        label: '星星',
        name: 'star',
        path: require('@/assets/img/taluopai/star.jpg')
      },{
        label: '力量',
        name: 'strength',
        path: require('@/assets/img/taluopai/strength.jpg')
      },{
        label: '太阳',
        name: 'sun',
        path: require('@/assets/img/taluopai/sun.jpg')
      },{
        label: '节制',
        name: 'temperance',
        path: require('@/assets/img/taluopai/temperance.jpg')
      },{
        label: '高塔',
        name: 'tower',
        path: require('@/assets/img/taluopai/tower.jpg')
      },{
        label: '命运之轮',
        name: 'wheel_of_fortune',
        path: require('@/assets/img/taluopai/wheel_of_fortune.jpg')
      },{
        label: '世界',
        name: 'world',
        path: require('@/assets/img/taluopai/world.jpg')
      }],
      smzx:[
        {
          address: "广东省深圳市福田区福中路321号靠近深圳市民中心",
          lng: 114.066052,
          lat: 22.549305
        },{
          address: "广东省深圳市福田区福中三路2008号靠近市民中心(地铁站)",
          lng: 113.066052,
          lat: 23.549305
        },{
          address: "广东省深圳市福田区红荔路2014-5号靠近少年宫(地铁站)",
          lng: 112.966052,
          lat: 23.149325
        },{
          address: "广东省深圳市福田区福中一路198号靠近深圳市当代艺术与城市规划馆",
          lng: 112.966052,
          lat: 23.149325
        },{
          address: "广东省深圳市福田区福中路223号靠近深圳市民中心",
          lng: 114.067821,
          lat: 22.546681
        },{
          address: "广东省深圳市福田区福中三路1138号靠近深圳市民中心",
          lng: 114.067793,
          lat: 22.546613
        },{
          address: "广东省深圳市福田区福中三路218号靠近深圳市民中心",
          lng: 114.067741,
          lat: 22.546632
        },{
          address: "广东省深圳市福田区福中三路2024号靠近市民广场",
          lng: 114.06632,
          lat: 22.547139
        },{
          address: "广东省深圳市福田区深南大道辅路2024号靠近市民广场",
          lng: 114.067645,
          lat: 22.546602
        },{
          address: "广东省深圳市福田区深南大道2020号靠近福田站（15进站口）",
          lng: 114.065069,
          lat: 22.546258
        },{
          address: "广东省深圳市福田区益田路4001号靠近福田站",
          lng: 114.063833,
          lat: 22.543882
        },{
          address: "广东省深圳市福田区深南大道200号靠近福田（地铁站）",
          lng: 114.058972,
          lat: 22.544643
        }
      ]
      ,
      fwzx:{
        address: "深圳市福田区深南大道1006号深圳国际创新中心F座附近",
        lng: 114.078168,
        lat: 22.54793
      },
      preventChangeOnSelect: false, // 添加一个标志位
      searchResults: [],
      linewell:{
        username: "",
        password: ""
      },
      requireEncrypt: false,
      linewellStatus: false,
      center: {
        lng: 0,
        lat: 0
      },
      zoom: 10,
      model: {
        lng: '',
        lat: '',
        address: ''
      },
      map: null,
      searchLocationValue: '',
      local: null,
      currentPage: 1,   //当前页码
      pageSize: 10,   //每页展示的条数
      total: 0,
      work: {},
      work2: {},
      randomCard: {},
      flippedFlag: false,
    };
  },
  created() {
    this.getLinewellUserInfo();
    this.setRandomItem();
  },
  mounted() {
    this.initMap();
  },
  methods:{
    setRandomItem() {
      const today = new Date().toISOString().split('T')[0]; // 获取今天的日期
      const seed = this.getSeedFromDate(today); // 使用日期生成种子
      const randomIndex = seed % this.cards.length; // 计算随机索引
      this.randomCard = this.cards[randomIndex]; // 设置随机选择的项目
    },
    getSeedFromDate(date) {
      // 使用日期字符串的 ASCII 码之和作为随机种子
      return Array.from(date).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    },
    confirmFilpped(){
      this.work = this.work2
      this.showDialog = false
    },
    restartFilpped(){
      this.work2 = {}
      this.fontImg = require('@/assets/img/taluopai/empty.jpg')
      this.backImg = require('@/assets/img/taluopai/empty.jpg')
      this.filppedStartMethod()
    },
    flippedEndMethod(){
      this.work2 =  this.smzx[Math.floor(Math.random()*this.smzx.length)]
      this.flippedFlag = false
    },
    filppedStartMethod(){
      this.isFlipped = !this.isFlipped
      this.flippedFlag = true
      setTimeout(()=>{
        if (this.isFlipped){
          this.backImg = this.randomCard.path
        }else{
          this.fontImg = this.randomCard.path
        }

      },3000)
    },
    getSmzx(){
      console.log(1111,this.smzx[Math.floor(Math.random()*this.smzx.length)])
      this.work =  this.smzx[Math.floor(Math.random()*this.smzx.length)]
    },
    getCard(){
      this.showDialog = true
      if (!this.work2.address){
        setTimeout(()=>{
          this.restartFilpped()
        },1000)
      }


    },
    workSign(){
      const h = this.$createElement;
      if (!this.work.address || !this.work.lng || !this.work.lat){
        this.$notify({
          title: '打卡信息不正确',
          message: h('i', { style: 'color: teal'}, '打卡信息缺失'),
          type: 'warning'
        });
        return
      }
      workSign(this.work.address,this.work.lng,this.work.lat).then(res => {
        if (res.code == 200){
          this.$notify({
            title: '打卡成功',
            message: h('i', { style: 'color: teal'}, '打卡成功'),
            type: 'success'
          });
        }
      })
    },
    confirmAddress(){
      this.work.address = this.searchLocationValue
      this.work.lng = this.center.lng
      this.work.lat = this.center.lat
      this.$forceUpdate()
      console.log('this.work',this.work)
    },
    handleCurrentChange(value){
      console.log('handleCurrentChange',value)
      this.local.gotoPage(value-1)
    },
    searchLocationValueChange(){
      console.log('this.preventChangeOnSelect',this.preventChangeOnSelect)
      if (!this.preventChangeOnSelect) {
        this.searchLocation()
      }
    },
    searchLocationValueSelect(value){
      console.log('xxxxx',value)
      // 点击下拉数据时设置标志位为true，以阻止change事件的触发
      this.preventChangeOnSelect = true;
      const searchResult = this.searchResults.find(result => result.id == value)
      this.searchLocationValue = searchResult.address

      console.log('点击位置经纬度：' + searchResult.lng + ',' + searchResult.lat);
      this.center.lng = searchResult.lng;
      this.center.lat = searchResult.lat;
      //清除之前的标记
      this.map.clearOverlays();
      // 创建点标记
      var point = new BMap.Point(this.center.lng, this.center.lat);

      // this.getAddrByPoint(point)
      // 获取位置信息
      var geocoder = new BMap.Geocoder();
      const _this = this;
      geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
        //清除之前的标记
        _this.map.clearOverlays()
        _this.map.addControl(new BMap.NavigationControl());
        var marker = new BMap.Marker(point);
        // 创建标注
        _this.map.addOverlay(marker);
        _this.map.panTo(point)
        //地址定位成功
        var address = geocoderResult.address;
        console.log("所处地址", address)
      });


      console.log('this.searchLocationValue',this.searchLocationValue)
      // 延迟将标志位设置为false，以确保在选择后再次允许change事件触发
      setTimeout(() => {
        this.preventChangeOnSelect = false;
      }, 0);

    },
    getLinewellUserInfo(){
      getLinewellUserInfo().then(res => {
        if (res.data){
          this.linewell.username = res.data.username
          this.linewell.password = res.data.password
          this.requireEncrypt = false
          if(res.data.validate == '1'){
            // 获取今天的日期
            const today = moment().startOf('day');

            const userTokenExpires = moment(res.data.userTokenExpires);

            if (today.isAfter(userTokenExpires)){
              this.linewellStatus = false
            }else  {
              this.linewellStatus = true
            }
          }else{
            this.linewellStatus = false
          }
        }
      })
    },

    handleLinewellLogin(){
      this.linewell.password = md5(this.linewell.password).toUpperCase();
      setLinewellUser(this.linewell.username,this.linewell.password).then(res => {
        console.log(22222,res)
      })
    },
    initMap() {
      // 创建Map实例 注意要和上面写的div的id名一样
      this.map = new BMap.Map("handMap");

      // 初始化地图,设置初始化位置，及地图级别
      var initPoint=new BMap.Point(114.066182, 22.549351);
      this.map.centerAndZoom(initPoint, 12);
      // 开启鼠标滚轮缩放
      this.map.enableScrollWheelZoom(true);

      // 创建标注
      this.map.addOverlay(new BMap.Marker(initPoint));

      const _this = this;
      //添加地图点击事件
      this.map.addEventListener('click', function(e) {

        console.log('点击位置经纬度：' + e.point.lng + ',' + e.point.lat);
        _this.center.lng = e.point.lng;
        _this.center.lat = e.point.lat;
        //清除之前的标记
        _this.map.clearOverlays();
        // 创建点标记
        var point = new BMap.Point(_this.center.lng, _this.center.lat);
        //获取位置信息
        var geocoder = new BMap.Geocoder();
        geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
          //清除之前的标记
          _this.map.clearOverlays()
          _this.map.addControl(new BMap.NavigationControl());
          var marker = new BMap.Marker(point);
          // 创建标注
          _this.map.addOverlay(marker);
          //地址定位成功
          var address = geocoderResult.address;
          console.log("所处地址", address)
        });
      });
    },
    /**
     * 获取自己的位置
     */
    fixedPosition() {
      const _this = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var mk = new BMap.Marker(r.point);
          _this.map.addOverlay(mk);
          _this.map.panTo(r.point);
          _this.center.lng = r.point.lng;
          _this.center.lat = r.point.lat;

          console.log('aaaa',r.address)
          var city_name = r.address.province + r.address.city;
          _this.searchLocationValue = r.address.province + r.address.city + r.address.street + r.address.street_number;
          console.log("当前位置--》", city_name);
          console.log("当前位置经纬度--》", r.point.lng,r.point.lat);
        } else {
          console.log('获取失败');
        }
      });
    },
    /**
     * 地图搜索
     */
    searchLocation() {
      this.local = new BMap.LocalSearch(this.map, {
        // renderOptions: {
        //   map: this.map
        // },
        onSearchComplete: this.searchCallback //设置回调函数
      });
      this.local.search(this.$refs.searchSelect.$data.selectedLabel); //设置搜索参数

    },
    /**
     * 搜索结果的回调函数
     */
    searchCallback() {

      console.log('this.local?.getResults()?.getPageIndex()',this.local?.getResults()?.getNumPages())
      this.total = this.local?.getResults()?.getNumPois() || 0
      this.currentPage = this.local?.getResults()?.getPageIndex()+1 || 1
      if (this.local?.getResults()?.getCurrentNumPois()){
        const options = []
        const results = this.local?.getResults()
        for(let i=0;i<results.getCurrentNumPois();i++){
          const pois = results.getPoi(i);
          console.log('pois',pois)
          const info = {id:i,address: pois.address,lng:pois.point.lng,lat:pois.point.lat};
          options.push(info)
        }
        this.searchResults =  options;
        console.log('this.searchResults',this.searchResults)
      }else
        this.searchResults =  []


      // var point = this.local.getResults().getPoi(0).point;
      // //获取第一个智能搜索的结果
      // console.log("当前位置的经纬度",point.lng, point.lat);
      // this.model.lat=point.lat;
      // this.model.lng=point.lng;

    },
    /**
     * 逆地址解析函数（根据坐标点获取详细地址）
     * @param {Object} point   百度地图坐标点，必传
     */
    getAddrByPoint(point){
      var that = this;
      var geco = new BMap.Geocoder();
      geco.getLocation(point, function(res){
        console.log(res)  //内容见下图
        // that.mk.setPosition(point) //重新设置标注的地理坐标
        that.map.panTo(point)  //将地图的中心点更改为给定的点
        that.form.address = res.address;  //记录该点的详细地址信息
        that.form.addrPoint = point;  //记录当前坐标点
      })
    },
    debounce(func, delay) {
      let timerId;
      return function(...args) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}

.taluopai-box{
  text-align: center;

}

.taluopai{
  //height: 10%;
  width: 80%;
  //left: 160px;
  //top: -28px;
  border-radius: 10%;


}

/* 卡片容器 */
.card-container {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  margin-top: -20px;
  padding: 20px;
}

/* 卡片样式 */
.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 5s cubic-bezier(0.1, 0.7, 0.1, 1);
}

/* 控制翻转的类 */
.card.flipped {
  transform: rotateY(540deg);
}

/* 卡片的正面和背面 */
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* 背面图片旋转 */
.card-back {
  transform: rotateY(180deg);
  transition: opacity 0.5s ease; /* 过渡效果 */
  opacity: 1; /* 默认透明度为 1 */
}

/* 圆角效果 */
.card img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

img[style*="opacity: 0"] {
  opacity: 0; /* 设置透明度为 0 */
}

/* 定义淡入效果的 keyframes 动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 应用淡入效果 */
.fade-in-image {
  animation: fadeIn 2s ease; /* 淡入效果持续 1 秒 */
}

.dialog-address{
  //margin: 20px;
  width: 300px;
  padding: 20px;
}

.shining-text {
  font-size: 22px; /* 字体大小 */
  color: #E85285; /* 初始颜色 */
  animation: shine 1s infinite; /* 应用动画 */
}

@keyframes shine {
  0%, 100% {
    opacity: 1; /* 完全不透明 */
  }
  50% {
    opacity: 0.5; /* 半透明 */
  }
}
</style>
