<template>
  <div class="md-layout work">
    <div
        class="md-layout-item md-size-100 md-small-size-100 mx-auto"
    >
      <h4 class="title">
        地图选点
      </h4>
      <div class="md-layout collections">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <md-card>

            <md-card-header>
              <div id="handMap" style="height: 300px"></div>
            </md-card-header>
            <md-card-content>
              <el-select
                  ref="searchSelect"
                  v-model="searchLocationValue"
                  filterable
                  placeholder="输入地点进行搜索"
                  @change="searchLocationValueSelect"
                  @input.native="debounce(searchLocationValueChange(),500)"
                  :filter-method="()=>searchResults"
              >
                <el-option
                    v-for="(item,index) in searchResults"
                    :key="index"
                    :label="item.address"
                    :value="item.id">
                </el-option>
                <div  style="bottom: -10px">
                  <el-pagination
                      small
                      @current-change="handleCurrentChange"
                      :current-page="currentPage"
                      :page-size="pageSize"
                      layout="prev, pager,next,total"
                      :total="total"
                  >
                  </el-pagination>
                </div>
              </el-select>
            </md-card-content>

            <md-card-actions>
              <md-button @click="confirmAddress">将此地加入打卡地址列表</md-button>
              <md-button @click="fixedPosition">获取当前位置</md-button>
            </md-card-actions>
          </md-card>

        </div>

      </div>
    </div>
    <div
        class="md-layout-item md-size-100 md-small-size-100 mx-auto stats"
    >
      <h4 class="title title-margin" v-if="!work.address">
        地点列表<small>（随机取列表中的一个进行打卡）</small>
      </h4>

      <md-card>
        <md-list class="md-double-line">
          <div v-for="(item,index) in form.addressList" :key="index">

            <md-divider v-if="index>0"></md-divider>

            <md-subheader>{{item.address}}</md-subheader>

            <md-list-item>
              <md-icon class="md-primary md-size-10">location_on</md-icon>

              <div class="md-list-item-text">
                <span style="color:black">经度：{{item.lng}}</span>
                <p class="text-container" style="line-height: 1.25em;font-size: 16px;font-weight: 400;color:black">纬度：{{item.lat}}</p>
              </div>

              <md-button class="md-list-action"  @click="deleteAddress(index)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-list-item>
          </div>

        </md-list>
        <p class="description" >
          <md-button @click="smzxConfig" >使用市民中心推荐的配置列表</md-button>
        </p>
        <p class="description" >
          <md-button @click="zwfwzxConfig" >使用政务服务中心推荐的配置列表</md-button>
        </p>
      </md-card>


    </div>

    <div
        class="md-layout-item md-size-100 md-small-size-100 mx-auto stats"
    >
      <h4 class="title title-margin" v-if="!work.address">
        详细配置
      </h4>

      <md-card>
        <div class="md-layout md-gutter" style="padding: 15px">

          <div class="md-layout-item md-size-100">
            <md-switch @change="(e)=>form.status = e || '0'" v-model="form.status" value="1">自动打卡开启状态</md-switch>
          </div>



        </div>
        <div class="md-layout md-gutter" style="padding: 15px">

          <div class="md-layout-item md-size-100">
            <md-switch @change="(e)=>form.satStatus = e || '0'" v-model="form.satStatus" value="1">周六是否打卡<small>（不包含节假日）</small></md-switch>
          </div>
          <div class="md-layout-item md-size-100">
            <md-switch @change="(e)=>form.sunStatus = e || '0'" v-model="form.sunStatus" value="1">周天是否打卡<small>（不包含节假日）</small></md-switch>
          </div>


        </div>
        <div class="md-layout md-gutter" style="padding: 15px">


          <div class="md-layout-item md-size-100">
            <md-switch @change="(e)=>form.uniqueClock = e || '0'" v-model="form.uniqueClock" value="1">唯一打卡<small>（存在手动打卡或者I南威软件打卡记录时，不打卡）</small></md-switch>
          </div>

        </div>

        <div class="md-layout md-gutter" style="padding: 15px">

          <div class="md-layout-item md-size-100">
            <md-switch @change="(e)=>form.smsAlerts = e || '0'" v-model="form.smsAlerts" value="1">是否短信提示<small>（打卡失败或者成功时发送短信提示）</small></md-switch>
          </div>

        </div>

        <div class="md-layout md-gutter" style="padding: 15px">

          <div class="md-layout-item md-size-100">
<!--            <span class="md-body-2" >是否上班打卡<small>（在该时间点前后1分钟随机打卡）</small></span>-->
            <md-switch @change="(e)=>form.upStatus = e || '0'" v-model="form.upStatus" value="1">打开上班打卡<small>（不打卡，打卡时间为8：58前后的随机时间）</small></md-switch>

<!--            <el-time-picker-->
<!--                disabled-->
<!--                value-format = 'HH:mm:ss'-->
<!--                value="8:58:00"-->
<!--                :picker-options="{-->
<!--      selectableRange: '8:30:00 - 8:58:00'-->
<!--    }"-->
<!--                placeholder="上班打卡时间">-->
<!--            </el-time-picker>-->
          </div>

          <div class="md-layout-item md-size-100" style="margin-top: 15px">
            <md-switch @change="(e)=>form.downStatus = e || '0'" v-model="form.downStatus" value="1">打开下班打卡<small>（打卡时间为20：02前后的随机时间）</small></md-switch>

            <!--            <span class="md-body-2" >下班打卡时间<small>（在该时间点前后1分钟随机打卡）</small></span>-->
<!--            <el-time-picker-->
<!--                value-format = 'HH:mm:ss'-->
<!--                v-model="form.downScheduledTime"-->
<!--                :picker-options="{-->
<!--      selectableRange: '18:00:00 - 23:58:00'-->
<!--    }"-->
<!--                placeholder="下班打卡时间">-->
<!--            </el-time-picker>-->
          </div>


        </div>
        <p class="description" >
          <md-button style="width: 100% !important;" @click="setAutoWorkSign" class="md-primary">提交配置</md-button>
        </p>
      </md-card>


    </div>

<!--    <div-->
<!--        class="md-layout-item md-size-100 md-small-size-100 mx-auto stats"-->
<!--    >-->
<!--      <h4 class="title title-margin" v-if="!work.address">-->
<!--        就这样！！！-->
<!--      </h4>-->

<!--    </div>-->

  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import md5 from 'js-md5';
import {getLinewellUserInfo, setAutoWorkSign, setLinewellUser, workSign} from "@/api/linewell";
import fa from "element-ui/src/locale/lang/fa";
import moment from "moment";


export default {
  components: {
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "manually-clock-page",
  computed:{

  },
  data() {
    return {
      addressList:[
        {address:'测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试',lng:1.01111,lat:2.0102},
        {address:'测试',lng:1.01111,lat:2.0102},
        {address:'测试',lng:1.01111,lat:2.0102},
        {address:'测试',lng:1.01111,lat:2.0102}
      ],
      smzx:{
        address: "广东省深圳市福田区福中路321号靠近深圳市民中心",
        lng: 114.066052,
        lat: 22.549305
      },
      fwzx:{
        address: "深圳市福田区深南大道1006号深圳国际创新中心F座附近",
        lng: 114.078168,
        lat: 22.54793
      },
      preventChangeOnSelect: false, // 添加一个标志位
      searchResults: [],
      linewell:{
        username: "",
        password: ""
      },
      requireEncrypt: false,
      linewellStatus: false,
      center: {
        lng: 0,
        lat: 0
      },
      zoom: 10,
      model: {
        lng: '',
        lat: '',
        address: ''
      },
      map: null,
      searchLocationValue: '',
      local: null,
      currentPage: 1,   //当前页码
      pageSize: 10,   //每页展示的条数
      total: 0,
      work: {},
      form: {},
    };
  },
  created() {
    this.getLinewellUserInfo();
  },
  mounted() {
    this.initMap();
  },
  methods:{
    setAutoWorkSign(){
      setAutoWorkSign(this.form).then(res => {
        console.log(1111,res)
      })
    },
    deleteAddress(index){
      this.form.addressList = this.form.addressList.slice(0, index).concat(this.form.addressList.slice(index+1));
      this.$forceUpdate()
    },
    submitConfig(){
      console.log(111,this.form)

    },
    smzxConfig(){
      this.form.addressList = this.addressList
      this.$forceUpdate()
    },
    zwfwzxConfig(){
      this.form.addressList = this.addressList
      this.$forceUpdate()
    },
    workSign(){
      const h = this.$createElement;
      if (!this.work.address || !this.work.lng || !this.work.lat){
        this.$notify({
          title: '打卡信息不正确',
          message: h('i', { style: 'color: teal'}, '打卡信息缺失'),
          type: 'warning'
        });
        return
      }
      workSign(this.work.address,this.work.lng,this.work.lat).then(res => {
        if (res.code == 200){
          this.$notify({
            title: '打卡成功',
            message: h('i', { style: 'color: teal'}, '打卡成功'),
            type: 'success'
          });
        }
      })
    },
    confirmAddress(){
      this.work.address = this.searchLocationValue
      this.work.lng = this.center.lng
      this.work.lat = this.center.lat
      this.$forceUpdate()
      console.log('this.work',this.work)
    },
    handleCurrentChange(value){
      console.log('handleCurrentChange',value)
      this.local.gotoPage(value-1)
    },
    searchLocationValueChange(){
      console.log('this.preventChangeOnSelect',this.preventChangeOnSelect)
      if (!this.preventChangeOnSelect) {
        this.searchLocation()
      }
    },
    searchLocationValueSelect(value){
      console.log('xxxxx',value)
      // 点击下拉数据时设置标志位为true，以阻止change事件的触发
      this.preventChangeOnSelect = true;
      const searchResult = this.searchResults.find(result => result.id == value)
      this.searchLocationValue = searchResult.address

      console.log('点击位置经纬度：' + searchResult.lng + ',' + searchResult.lat);
      this.center.lng = searchResult.lng;
      this.center.lat = searchResult.lat;
      //清除之前的标记
      this.map.clearOverlays();
      // 创建点标记
      var point = new BMap.Point(this.center.lng, this.center.lat);

      // this.getAddrByPoint(point)
      // 获取位置信息
      var geocoder = new BMap.Geocoder();
      const _this = this;
      geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
        //清除之前的标记
        _this.map.clearOverlays()
        _this.map.addControl(new BMap.NavigationControl());
        var marker = new BMap.Marker(point);
        // 创建标注
        _this.map.addOverlay(marker);
        _this.map.panTo(point)
        //地址定位成功
        var address = geocoderResult.address;
        console.log("所处地址", address)
      });


      console.log('this.searchLocationValue',this.searchLocationValue)
      // 延迟将标志位设置为false，以确保在选择后再次允许change事件触发
      setTimeout(() => {
        this.preventChangeOnSelect = false;
      }, 0);

    },
    getLinewellUserInfo(){
      getLinewellUserInfo().then(res => {
        if (res.data){
          this.linewell.username = res.data.username
          this.linewell.password = res.data.password
          this.requireEncrypt = false
          if(res.data.validate == '1'){
            // 获取今天的日期
            const today = moment().startOf('day');

            const userTokenExpires = moment(res.data.userTokenExpires);

            if (today.isAfter(userTokenExpires)){
              this.linewellStatus = false
            }else  {
              this.linewellStatus = true
            }
          }else{
            this.linewellStatus = false
          }
        }
      })
    },

    handleLinewellLogin(){
      this.linewell.password = md5(this.linewell.password).toUpperCase();
      setLinewellUser(this.linewell.username,this.linewell.password).then(res => {
        console.log(22222,res)
      })
    },
    initMap() {
      // 创建Map实例 注意要和上面写的div的id名一样
      this.map = new BMap.Map("handMap");

      // 初始化地图,设置初始化位置，及地图级别
      var initPoint=new BMap.Point(114.066182, 22.549351);
      this.map.centerAndZoom(initPoint, 12);
      // 开启鼠标滚轮缩放
      this.map.enableScrollWheelZoom(true);

      // 创建标注
      this.map.addOverlay(new BMap.Marker(initPoint));

      const _this = this;
      //添加地图点击事件
      this.map.addEventListener('click', function(e) {

        console.log('点击位置经纬度：' + e.point.lng + ',' + e.point.lat);
        _this.center.lng = e.point.lng;
        _this.center.lat = e.point.lat;
        //清除之前的标记
        _this.map.clearOverlays();
        // 创建点标记
        var point = new BMap.Point(_this.center.lng, _this.center.lat);
        //获取位置信息
        var geocoder = new BMap.Geocoder();
        geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
          //清除之前的标记
          _this.map.clearOverlays()
          _this.map.addControl(new BMap.NavigationControl());
          var marker = new BMap.Marker(point);
          // 创建标注
          _this.map.addOverlay(marker);
          //地址定位成功
          var address = geocoderResult.address;
          console.log("所处地址", address)
        });
      });
    },
    /**
     * 获取自己的位置
     */
    fixedPosition() {
      const _this = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var mk = new BMap.Marker(r.point);
          _this.map.addOverlay(mk);
          _this.map.panTo(r.point);
          _this.center.lng = r.point.lng;
          _this.center.lat = r.point.lat;

          console.log('aaaa',r.address)
          var city_name = r.address.province + r.address.city;
          _this.searchLocationValue = r.address.province + r.address.city + r.address.street + r.address.street_number;
          console.log("当前位置--》", city_name);
          console.log("当前位置经纬度--》", r.point.lng,r.point.lat);
        } else {
          console.log('获取失败');
        }
      });
    },
    /**
     * 地图搜索
     */
    searchLocation() {
      this.local = new BMap.LocalSearch(this.map, {
        // renderOptions: {
        //   map: this.map
        // },
        onSearchComplete: this.searchCallback //设置回调函数
      });
      this.local.search(this.$refs.searchSelect.$data.selectedLabel); //设置搜索参数

    },
    /**
     * 搜索结果的回调函数
     */
    searchCallback() {

      console.log('this.local?.getResults()?.getPageIndex()',this.local?.getResults()?.getNumPages())
      this.total = this.local?.getResults()?.getNumPois() || 0
      this.currentPage = this.local?.getResults()?.getPageIndex()+1 || 1
      if (this.local?.getResults()?.getCurrentNumPois()){
        const options = []
        const results = this.local?.getResults()
        for(let i=0;i<results.getCurrentNumPois();i++){
          const pois = results.getPoi(i);
          console.log('pois',pois)
          const info = {id:i,address: pois.address,lng:pois.point.lng,lat:pois.point.lat};
          options.push(info)
        }
        this.searchResults =  options;
        console.log('this.searchResults',this.searchResults)
      }else
        this.searchResults =  []


      // var point = this.local.getResults().getPoi(0).point;
      // //获取第一个智能搜索的结果
      // console.log("当前位置的经纬度",point.lng, point.lat);
      // this.model.lat=point.lat;
      // this.model.lng=point.lng;

    },
    /**
     * 逆地址解析函数（根据坐标点获取详细地址）
     * @param {Object} point   百度地图坐标点，必传
     */
    getAddrByPoint(point){
      var that = this;
      var geco = new BMap.Geocoder();
      geco.getLocation(point, function(res){
        console.log(res)  //内容见下图
        // that.mk.setPosition(point) //重新设置标注的地理坐标
        that.map.panTo(point)  //将地图的中心点更改为给定的点
        that.form.address = res.address;  //记录该点的详细地址信息
        that.form.addrPoint = point;  //记录当前坐标点
      })
    },
    debounce(func, delay) {
      let timerId;
      return function(...args) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}

.md-list {
  border: none;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  //border: 1px solid rgba(#000, .12);
  padding: 0 5px !important;
}

//.md-double-line {
//  width: 100%;
//}




</style>
