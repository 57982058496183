<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter"
      parallax-active="true"
      :style="headerStyle"
    />
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img
                    :src="img"
                    alt="Circle Image"
                    class="img-raised rounded-circle img-fluid"
                  >
                </div>
                <div class="name">
                  <h3 class="title">
                    莹莹子
                  </h3>
                  <h6>熬夜冠军</h6>
<!--                  <md-button-->
<!--                    href="javascript:void(0)"-->
<!--                    class="md-just-icon md-simple md-dribbble"-->
<!--                  >-->
<!--                    <i class="fab fa-dribbble" />-->
<!--                  </md-button>-->
<!--                  <md-button-->
<!--                    href="javascript:void(0)"-->
<!--                    class="md-just-icon md-simple md-twitter"-->
<!--                  >-->
<!--                    <i class="fab fa-twitter" />-->
<!--                  </md-button>-->
<!--                  <md-button-->
<!--                    href="javascript:void(0)"-->
<!--                    class="md-just-icon md-simple md-pinterest"-->
<!--                  >-->
<!--                    <i class="fab fa-pinterest" />-->
<!--                  </md-button>-->
                </div>
                <div class="follow">
                  <md-button class="md-warning md-just-icon md-round">
                    <md-icon>add</md-icon>
                    <md-tooltip md-direction="top">
                      添加到自动打卡配置
                    </md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="description text-center">
            <p>
              白昼行将，暗夜魔王
            </p>
          </div>
          <div class="profile-tabs">
            <tabs
              :tab-active="1"
              :tab-name="['打卡配置','手动打卡', '打卡记录', '自动打卡']"
              :tab-icon="['home','fingerprint', 'calendar_today', 'history']"
              plain
              nav-pills-icons
              color-button="warning"
            >
              <!-- here you can add your content for tab-content -->
              <template slot="tab-pane-1">
<!--                <linewell-login v-if="false"/>-->
                <div class="md-layout work">
                  <div
                      class="md-layout-item md-size-60 md-small-size-100 mx-auto"
                  >
                    <h4 class="title">
                      i南威账号和密码
                    </h4>
                    <div class="md-layout collections">
                      <div class="md-layout-item md-size-100 md-small-size-100" style="display: flex;flex-direction: column;align-items: center;justify-content: center">
                        <md-field
                            slot="inputs"
                            class="md-form-group"
                        >
                          <md-icon>face</md-icon>
                          <label>用户名...</label>
                          <md-input v-model="linewell.username" />
                        </md-field>

                        <md-field
                            slot="inputs"
                            class="md-form-group"
                        >
                          <md-icon>lock_outline</md-icon>
                          <label>密码...</label>
                          <md-input v-model="linewell.password" @change.native="requireEncrypt = true" type="password"/>
                        </md-field>
                        <md-button
                            slot="footer"
                            class="md-simple md-success md-lg"
                            @click="handleLinewellLogin"
                        >
                          绑定i南威账号
                        </md-button>
                      </div>
                    </div>
                  </div>

                  <div
                      class="md-layout-item md-size-20 md-small-size-100 mx-auto stats"
                      style="display: flex;flex-direction: column;align-items: center;justify-content: center"
                  >
                    <h4 class="title title-margin">
                      i南威账号状态
                    </h4>
                    <md-icon class="md-size-5x" v-if="linewellStatus">done</md-icon>
                    <md-icon class="md-size-5x" v-else>info</md-icon>

                  </div>
                </div>
              </template>

              <template slot="tab-pane-2">
                <manually-clock/>
              </template>
              <template slot="tab-pane-3">
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-40 md-small-size-100 mx-auto"
                    style="display: flex;justify-content: center;align-items: center"
                  >
<!--                    还没做哦-->
                    <profile-card
                      card-plain
                      type="horizontal"
                      :shadow-normal="false"
                      :no-colored-shadow="false"
                      :card-image="cardProfile3.cardProfile1"
                      v-if="workSignToday[0]"
                    >
                      <template slot="cardContent">
                        <h4 class="card-title">
                          {{ workSignToday[0].checkinoutWorkStatus }}
                        </h4>
                        <h6 class="card-category text-muted">
                          {{ workSignToday[0].timeStr }}
                        </h6>
                        <p class="card-description">
                          {{ workSignToday[0].address }}
                        </p>
                      </template>
                    </profile-card>
                  </div>
                  <div
                    class="md-layout-item md-size-40 md-small-size-100 mx-auto"
                  >
                    <profile-card
                      card-plain
                      type="horizontal"
                      :shadow-normal="false"
                      :no-colored-shadow="false"
                      :card-image="cardProfile3.cardProfile2"
                      v-if="workSignToday[1]"
                    >
                      <template slot="cardContent">
                        <h4 class="card-title">
                          {{ workSignToday[1].checkinoutWorkStatus }}
                        </h4>
                        <h6 class="card-category text-muted">
                          {{ workSignToday[1].timeStr }}
                        </h6>
                        <p class="card-description">
                          {{ workSignToday[1].address }}
                        </p>
                      </template>
                    </profile-card>
                  </div>
                </div>
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-40 md-small-size-100 mx-auto"
                  >
                    <profile-card
                      card-plain
                      type="horizontal"
                      :shadow-normal="false"
                      :no-colored-shadow="false"
                      :card-image="cardProfile3.cardProfile3"
                      v-if="workSignToday[2]"
                    >
                      <template slot="cardContent">
                        <h4 class="card-title">
                          {{ workSignToday[2].checkinoutWorkStatus }}
                        </h4>
                        <h6 class="card-category text-muted">
                          {{ workSignToday[2].timeStr }}
                        </h6>
                        <p class="card-description">
                          {{ workSignToday[2].address }}
                        </p>
                      </template>
                    </profile-card>
                  </div>
                  <div
                    class="md-layout-item md-size-40 md-small-size-100 mx-auto"
                  >
                    <profile-card
                      card-plain
                      type="horizontal"
                      :shadow-normal="false"
                      :no-colored-shadow="false"
                      :card-image="cardProfile3.cardProfile4"
                      v-if="workSignToday[3]"
                    >
                      <template slot="cardContent">
                        <h4 class="card-title">
                          {{ workSignToday[3].checkinoutWorkStatus }}
                        </h4>
                        <h6 class="card-category text-muted">
                          {{ workSignToday[3].timeStr }}
                        </h6>
                        <p class="card-description">
                          {{ workSignToday[3].address }}
                        </p>
                      </template>
                    </profile-card>
                  </div>
                  <div
                      class="md-layout-item md-size-40 md-small-size-100 mx-auto"
                  >
                    <profile-card
                        card-plain
                        type="horizontal"
                        :shadow-normal="false"
                        :no-colored-shadow="false"
                        :card-image="cardProfile3.cardProfile5"
                        v-if="!workSignToday.length"
                    >
                      <template slot="cardContent">
                        <h4 class="card-title">
                          今天还没有打卡记录~
                        </h4>
                        <h6 class="card-category text-muted">
                          记得打卡哦
                        </h6>
                        <p class="card-description">
                          记得打卡哦
                        </p>
                      </template>
                    </profile-card>
                  </div>
                </div>
              </template>
              <template slot="tab-pane-4">
                <div class="md-layout" style="display: flex;justify-content: center;align-items: center">
                  <auto-clock/>
<!--                  <div-->
<!--                    class="md-layout-item md-size-25 md-small-size-100 ml-auto"-->
<!--                  >-->
<!--                    <img-->
<!--                      :src="tabPane3[0].image"-->
<!--                      class="rounded"-->
<!--                    >-->
<!--                    <img-->
<!--                      :src="tabPane3[1].image"-->
<!--                      class="rounded"-->
<!--                    >-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="md-layout-item md-size-25 md-small-size-100 mr-auto"-->
<!--                  >-->
<!--                    <img-->
<!--                      :src="tabPane3[2].image"-->
<!--                      class="rounded"-->
<!--                    >-->
<!--                    <img-->
<!--                      :src="tabPane3[3].image"-->
<!--                      class="rounded"-->
<!--                    >-->
<!--                    <img-->
<!--                      :src="tabPane3[4].image"-->
<!--                      class="rounded"-->
<!--                    >-->
<!--                  </div>-->
                </div>
              </template>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, FullBgCard, ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";
import md5 from 'js-md5';
import {getLinewellUserInfo, getTodayWorkSign, setLinewellUser} from "@/api/linewell";
import fa from "element-ui/src/locale/lang/fa";
import moment from "moment";
import ManuallyClock from "@/views/examples/ManuallyClock.vue";
import LinewellLogin from "@/views/examples/LinewellLogin.vue";
import AutoClock from "@/views/examples/AutoClock.vue";

export default {
  components: {
    Tabs,
    // eslint-disable-next-line vue/no-unused-components
    FullBgCard,
    ProfileCard,
    ManuallyClock,
    // LinewellLogin
    AutoClock
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "profile-page",
  computed:{

  },
  data() {
    return {
      workSignToday:[],
      preventChangeOnSelect: false, // 添加一个标志位
      searchResults: [],
      // image: require("@/assets/img/city-profile.jpg"),
    image: require("@/assets/img/lnbe/xDSGydp2S2zZaoQ.jpg"),
      // img: require("@/assets/img/faces/christian.jpg")
       img: require("@/assets/img/lnbe/20220118214604_a9405.jpg"),
      cardFullBg: {
        fullBg1: require("@/assets/img/examples/mariya-georgieva.jpg"),
        fullBg2: require("@/assets/img/examples/clem-onojeghuo.jpg"),
        fullBg3: require("@/assets/img/examples/olu-eletu.jpg"),
        fullBg4: require("@/assets/img/examples/floralia.jpg")
      },
      cardProfile3: {
        cardProfile1: require("@/assets/img/lnbe/20211027120941_2a434.jpeg"),
        cardProfile2: require("@/assets/img/lnbe/20211130120629_d1b39.jpeg"),
        cardProfile3: require("@/assets/img/lnbe/20211027120941_2a434.jpeg"),
        cardProfile4: require("@/assets/img/lnbe/20211130120629_d1b39.jpeg"),
        cardProfile5: require("@/assets/img/lnbe/20211017195505_effcb.jpeg"),
        // cardProfile1: require("@/assets/img/faces/avatar.jpg"),
        // cardProfile2: require("@/assets/img/faces/marc.jpg"),
        // cardProfile3: require("@/assets/img/faces/kendall.jpg"),
        // cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg")
      },
      tabPane1: [
        { image: require("@/assets/img/examples/studio-1.jpg") },
        { image: require("@/assets/img/examples/studio-2.jpg") },
        { image: require("@/assets/img/examples/studio-4.jpg") },
        { image: require("@/assets/img/examples/studio-5.jpg") }
      ],
      tabPane2: [
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/cynthia-del-rio.jpg") },
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/clem-onojegaw.jpg") }
      ],
      tabPane3: [
        { image: require("@/assets/img/examples/mariya-georgieva.jpg") },
        { image: require("@/assets/img/examples/studio-3.jpg") },
        { image: require("@/assets/img/examples/clem-onojeghuo.jpg") },
        { image: require("@/assets/img/examples/olu-eletu.jpg") },
        { image: require("@/assets/img/examples/studio-1.jpg") }
      ],
      linewell:{
        username: "",
        password: ""
      },
      requireEncrypt: false,
      linewellStatus: false,
      center: {
        lng: 0,
        lat: 0
      },
      zoom: 10,
      model: {
        lng: '',
        lat: '',
        address: ''
      },
      map: null,
      searchLocationValue: '',
      local: null,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      currentPage: 1,   //当前页码
      pageSize: 10,   //每页展示的条数
      total: 0,
    };
  },
  created() {
    this.getLinewellUserInfo();
  },
  mounted() {
    // this.initMap();
  },
  methods:{
    handleCurrentChange(value){
      this.local.gotoPage(value-1)
    },
    searchLocationValueChange(){
      console.log('this.preventChangeOnSelect',this.preventChangeOnSelect)
      if (!this.preventChangeOnSelect) {
        this.searchLocation()
      }
    },
    searchLocationValueSelect(value){
      console.log('xxxxx',value)
      // 点击下拉数据时设置标志位为true，以阻止change事件的触发
      this.preventChangeOnSelect = true;
      const searchResult = this.searchResults.find(result => result.id == value)
      this.searchLocationValue = searchResult.address

      console.log('点击位置经纬度：' + searchResult.lng + ',' + searchResult.lat);
      this.center.lng = searchResult.lng;
      this.center.lat = searchResult.lat;
      //清除之前的标记
      this.map.clearOverlays();
      // 创建点标记
      var point = new BMap.Point(this.center.lng, this.center.lat);

      // this.getAddrByPoint(point)
      // 获取位置信息
      var geocoder = new BMap.Geocoder();
      const _this = this;
      geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
        //清除之前的标记
        _this.map.clearOverlays()
        _this.map.addControl(new BMap.NavigationControl());
        var marker = new BMap.Marker(point);
        // 创建标注
        _this.map.addOverlay(marker);
        _this.map.panTo(point)
        //地址定位成功
        var address = geocoderResult.address;
        console.log("所处地址", address)
      });


      console.log('this.searchLocationValue',this.searchLocationValue)
      // 延迟将标志位设置为false，以确保在选择后再次允许change事件触发
      setTimeout(() => {
        this.preventChangeOnSelect = false;
      }, 0);

    },
    getTodayWorkSign(){
      getTodayWorkSign().then(res => {
        console.log(res)
        this.workSignToday = res.data || []
      })
    },
    getLinewellUserInfo(){
      getLinewellUserInfo().then(res => {
        if (res.data){
          this.linewell.username = res.data.username
          this.linewell.password = res.data.password
          this.requireEncrypt = false
          if(res.data.validate == '1'){
            // 获取今天的日期
            const today = moment().startOf('day');

            const userTokenExpires = moment(res.data.userTokenExpires);

            if (today.isAfter(userTokenExpires)){
              this.linewellStatus = false
            }else  {
              this.linewellStatus = true
              this.getTodayWorkSign()
            }
          }else{
            this.linewellStatus = false
          }
        }
      })
    },

    handleLinewellLogin(){
      const h = this.$createElement;
      this.linewell.password = md5(this.linewell.password).toUpperCase();
      setLinewellUser(this.linewell.username,this.linewell.password).then(res => {
        if (res.code == 200){
          this.$notify({
            title: '登录成功',
            message: h('i', { style: 'color: teal'}, '下次就不用再登录了'),
            type: 'success'
          });
          this.getLinewellUserInfo();
        }
      })
    },
    initMap() {
      // 创建Map实例 注意要和上面写的div的id名一样
      this.map = new BMap.Map("handMap");

      // 初始化地图,设置初始化位置，及地图级别
      var initPoint=new BMap.Point(114.066182, 22.549351);
      this.map.centerAndZoom(initPoint, 12);
      // 开启鼠标滚轮缩放
      this.map.enableScrollWheelZoom(true);

      // 创建标注
      this.map.addOverlay(new BMap.Marker(initPoint));

      const _this = this;
      //添加地图点击事件
      this.map.addEventListener('click', function(e) {

        console.log('点击位置经纬度：' + e.point.lng + ',' + e.point.lat);
        _this.center.lng = e.point.lng;
        _this.center.lat = e.point.lat;
        //清除之前的标记
        _this.map.clearOverlays();
        // 创建点标记
        var point = new BMap.Point(_this.center.lng, _this.center.lat);
        //获取位置信息
        var geocoder = new BMap.Geocoder();
        geocoder.getLocation(point, function(geocoderResult, LocationOptions) {
          //清除之前的标记
          _this.map.clearOverlays()
          _this.map.addControl(new BMap.NavigationControl());
          var marker = new BMap.Marker(point);
          // 创建标注
          _this.map.addOverlay(marker);
          //地址定位成功
          var address = geocoderResult.address;
          console.log("所处地址", address)
        });
      });
    },
    /**
     * 获取自己的位置
     */
    fixedPosition() {
      const _this = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var mk = new BMap.Marker(r.point);
          _this.map.addOverlay(mk);
          _this.map.panTo(r.point);
          _this.center.lng = r.point.lng;
          _this.center.lat = r.point.lat;

          console.log('aaaa',r.address)
          var city_name = r.address.province + r.address.city;
          _this.searchLocationValue = r.address.province + r.address.city + r.address.street + r.address.street_number;
          console.log("当前位置--》", city_name);
          console.log("当前位置经纬度--》", r.point.lng,r.point.lat);
        } else {
          console.log('获取失败');
        }
      });
    },
    /**
     * 地图搜索
     */
    searchLocation() {
      this.local = new BMap.LocalSearch(this.map, {
        // renderOptions: {
        //   map: this.map
        // },
        onSearchComplete: this.searchCallback //设置回调函数
      });
      this.local.search(this.$refs.searchSelect.$data.selectedLabel); //设置搜索参数

    },
    /**
     * 搜索结果的回调函数
     */
    searchCallback() {

      console.log('this.local?.getResults()?.getPageIndex()',this.local?.getResults()?.getNumPages())
      this.total = this.local?.getResults()?.getNumPois() || 0
      this.currentPage = this.local?.getResults()?.getPageIndex()+1 || 1
      if (this.local?.getResults()?.getCurrentNumPois()){
        const options = []
        const results = this.local?.getResults()
        for(let i=0;i<results.getCurrentNumPois();i++){
          const pois = results.getPoi(i);
          console.log('pois',pois)
          const info = {id:i,address: pois.address,lng:pois.point.lng,lat:pois.point.lat};
          options.push(info)
        }
        this.searchResults =  options;
        console.log('this.searchResults',this.searchResults)
      }else
        this.searchResults =  []


      // var point = this.local.getResults().getPoi(0).point;
      // //获取第一个智能搜索的结果
      // console.log("当前位置的经纬度",point.lng, point.lat);
      // this.model.lat=point.lat;
      // this.model.lng=point.lng;

    },
    /**
     * 逆地址解析函数（根据坐标点获取详细地址）
     * @param {Object} point   百度地图坐标点，必传
     */
    getAddrByPoint(point){
      var that = this;
      var geco = new BMap.Geocoder();
      geco.getLocation(point, function(res){
        console.log(res)  //内容见下图
        // that.mk.setPosition(point) //重新设置标注的地理坐标
        that.map.panTo(point)  //将地图的中心点更改为给定的点
        that.form.address = res.address;  //记录该点的详细地址信息
        that.form.addrPoint = point;  //记录当前坐标点
      })
    },
    debounce(func, delay) {
      let timerId;
      return function(...args) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
