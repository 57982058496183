import request from '@/utils/request'

// 获取南威用户
export function getBySysUserId() {
  return request({
    url: '/system/linewell-user',
    method: 'get'
  });
}

export function getLinewellUserInfo() {
  return request({
    url: '/system/linewell-user/getLinewellUserInfo',
    method: 'get'
  });
}

// 绑定南威用户
export function setLinewellUser(username, password) {
  const data = {
    username,
    password
  };
  return request({
    url: '/system/linewell-user',
    headers: {
      isToken: true,
      repeatSubmit: true
    },
    method: 'post',
    data: data
  });
}

export function refreshLinewellUserToken() {
  const data = {

  };
  return request({
    url: '/system/linewell-user/refreshLinewellUserToken',
    headers: {
      isToken: true,
      repeatSubmit: true
    },
    method: 'post',
    data: data
  });
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

export function workSign(address, lng,lat) {
  const data = {
    address,
    lng,
    lat
  };
  return request({
    url: '/system/linewell-user/workSign',
    headers: {
      isToken: true,
      repeatSubmit: true
    },
    method: 'post',
    data: data
  });
}

export function getTodayWorkSign() {
  return request({
    url: '/system/linewell-user/getTodayWorkSign',
    method: 'get'
  });
}

export function setAutoWorkSign(data) {
  return request({
    url: '/system/linewell-user/setAutoWorkSign',
    headers: {
      isToken: true,
      repeatSubmit: true
    },
    method: 'post',
    data: data
  });
}
