<template>
  <div class="wrapper">
    <div
      class="section page-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
          >
            <login-card header-color="rose" style="opacity: 0.8 !important;">
              <h4
                slot="title"
                class="card-title"
              >
                注意身体
              </h4>
              <md-button
                slot="buttons"
                href="javascript:void(0)"
                class="md-just-icon md-simple md-white"
                @click="showTips"
              >
                <i class="fa fa-bed" />
              </md-button>
              <md-button
                slot="buttons"
                href="javascript:void(0)"
                class="md-just-icon md-simple md-white"
                @click="showTips"
              >
                <i class="fa fa-bell" />
              </md-button>
<!--              <md-button-->
<!--                slot="buttons"-->
<!--                href="javascript:void(0)"-->
<!--                class="md-just-icon md-simple md-white"-->
<!--                @click="showTips"-->
<!--              >-->
<!--                <i class="fa fa-bell" />-->
<!--              </md-button>-->
              <p
                slot="description"
                class="description"
              >
                (｡◕‿◕｡✿)
              </p>
              <md-field
                slot="inputs"
                class="md-form-group"
              >
                <md-icon>face</md-icon>
                <label>用户名...</label>
                <md-input v-model="loginForm.username" />
              </md-field>

              <md-field
                slot="inputs"
                class="md-form-group"
              >
                <md-icon>lock_outline</md-icon>
                <label>密码...</label>
                <md-input v-model="loginForm.password" type="password"/>
              </md-field>
              <md-field
                  slot="inputs"
                  class="md-form-group"
              >
                <md-icon>code</md-icon>
                <label>验证码...</label>
                <md-input
                    v-model="loginForm.code"
                    type="code"
                />
                <div class="login-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
              </md-field>

              <md-button
                slot="footer"
                class="md-simple md-success md-lg"
                @click="handleLogin"
              >
                <md-checkbox v-model="remember">记住密码</md-checkbox>
                登录
              </md-button>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import Mixins from "@/plugins/basicMixins";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import {decrypt, encrypt} from "@/utils/jsencrypt";
export default {
  components: {
    LoginCard
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      remember: true,
      // image: require("@/assets/img/profile_city.jpg"),
      image: require("@/assets/img/lnbe/linabell1.jpg"),
      loading: false,
      codeUrl: "",
      captchaEnabled: true,
      redirect: undefined,
      loginForm: {
        username: "",
        password: "",
        rememberMe: true,
        code: "",
        uuid: ""
      },
    };
  },
  created() {
    this.getCode()
    this.getCookie()
  },
  methods:{
    showTips(){
      // const h = this.$createElement;
      // this.$notify({
      //   title: '记得打卡',
      //   message: h('i', { style: 'color: teal'}, '记得打卡记得打卡记得打卡记得打卡记得打卡'),
      //   type: 'success'
      // });
    },
    handleLogin() {
      let data = {
        username: this.loginForm.username,
        password: this.loginForm.password,
        code: this.loginForm.code,
        rememberMe: this.loginForm.rememberMe,
        uuid: this.loginForm.uuid,
      }
      this.loading = true;
      // if (this.loginForm.rememberMe) {
      //   Cookies.set("username", this.loginForm.username, { expires: 30 });
      //   Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
      // } else {
      //   Cookies.remove("username");
      //   Cookies.remove("password");
      // }
      this.$store.dispatch("Login", data).then(() => {
        if (this.remember){
          Cookies.set("username", this.loginForm.username, { expires: 30 });
          Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
        }
        this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
      }).catch(() => {
        this.getCode()
        this.loading = false;
      });
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
      };
    },
  }
};
</script>

<style lang="css"></style>
