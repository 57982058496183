<template>
  <div class="md-layout work">
    <div
        class="md-layout-item md-size-60 md-small-size-100 mx-auto"
    >
      <h4 class="title">
        i南威账号和密码
      </h4>
      <div class="md-layout collections">
        <div class="md-layout-item md-size-100 md-small-size-100" style="display: flex;flex-direction: column;align-items: center;justify-content: center">
          <md-field
              slot="inputs"
              class="md-form-group"
          >
            <md-icon>face</md-icon>
            <label>用户名...</label>
            <md-input v-model="linewell.username" />
          </md-field>

          <md-field
              slot="inputs"
              class="md-form-group"
          >
            <md-icon>lock_outline</md-icon>
            <label>密码...</label>
            <md-input v-model="linewell.password" @change.native="requireEncrypt = true" type="password"/>
          </md-field>
          <md-button
              slot="footer"
              class="md-simple md-success md-lg"
              @click="handleLinewellLogin"
          >
            绑定i南威账号
          </md-button>
        </div>
      </div>
    </div>

    <div
        class="md-layout-item md-size-20 md-small-size-100 mx-auto stats"
        style="display: flex;flex-direction: column;align-items: center;justify-content: center"
    >
      <h4 class="title title-margin">
        i南威账号状态
      </h4>
      <md-icon class="md-size-5x" v-if="linewellStatus">done</md-icon>
      <md-icon class="md-size-5x" v-else>info</md-icon>

    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import md5 from 'js-md5';
import {getLinewellUserInfo, setLinewellUser} from "@/api/linewell";
import fa from "element-ui/src/locale/lang/fa";
import moment from "moment";
import ManuallyClock from "@/views/examples/ManuallyClock.vue";

export default {
  components: {
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "profile-page",
  computed:{

  },
  data() {
    return {
      preventChangeOnSelect: false, // 添加一个标志位
      searchResults: [],
      linewell:{
        username: "",
        password: ""
      },
      requireEncrypt: false,
      linewellStatus: false,
    };
  },
  created() {
    this.getLinewellUserInfo();
  },
  mounted() {
  },
  methods:{
    getLinewellUserInfo(){
      getLinewellUserInfo().then(res => {
        if (res.data){
          this.linewell.username = res.data.username
          this.linewell.password = res.data.password
          this.requireEncrypt = false
          if(res.data.validate == '1'){
            // 获取今天的日期
            const today = moment().startOf('day');

            const userTokenExpires = moment(res.data.userTokenExpires);

            if (today.isAfter(userTokenExpires)){
              this.linewellStatus = false
            }else  {
              this.linewellStatus = true
            }
          }else{
            this.linewellStatus = false
          }
        }
      })
    },
    handleLinewellLogin(){
      this.linewell.password = md5(this.linewell.password).toUpperCase();
      setLinewellUser(this.linewell.username,this.linewell.password).then(res => {
        console.log(22222,res)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img:not(.img) {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
